import React from 'react';

import TextComponent from './types/text';
import ImageComponent from './types/image';
import VideoComponent from './types/video';
import FileComponent from './types/file';
import QuestionTextComponent from './types/question-text';
import QuestionSliderComponent from './types/question-slider';
import { QuestionMultipleChoiceScreenComponent } from './types/question';
import { DateTimeScreenComponent } from './types/date-time';
import { NumberScreenComponent } from './types/number';
import { FileUploadScreenComponent } from './types/file-upload';
import { DropdownScreenComponent } from './types/dropdown';
import { BooleanScreenComponent } from './types/boolean';
import { ChecklistScreenComponent } from './types/checklist';
import { EComponentTypes } from '../../../definitions';

import type { ObjectValues } from '@common/types/util-types';
import type { ScreenComponentItem } from '../../../types/objects';

type ScreenComponentOwnProps = {
  highlight?: boolean;
  onOpenComponent?: (screenComponent: ScreenComponentItem) => void;
  index?: number;
  item: any;
};

const getComponent = (type: ObjectValues<typeof EComponentTypes>) => {
  switch (type) {
    case EComponentTypes.TEXT:
      return TextComponent;
    case EComponentTypes.IMAGE:
      return ImageComponent;
    case EComponentTypes.EXTERNAL_VIDEO:
    case EComponentTypes.VIDEO:
      return VideoComponent;
    case EComponentTypes.ELEARNING_QUESTION:
    case EComponentTypes.QUESTION_MULTIPLE_CHOICE:
      return QuestionMultipleChoiceScreenComponent;
    case EComponentTypes.PDF:
      return FileComponent;
    case EComponentTypes.QUESTION_TEXT:
      return QuestionTextComponent;
    case EComponentTypes.QUESTION_SLIDER:
      return QuestionSliderComponent;
    case EComponentTypes.QUESTION_DATE_TIME:
      return DateTimeScreenComponent;
    case EComponentTypes.QUESTION_NUMBER:
      return NumberScreenComponent;
    case EComponentTypes.QUESTION_FILE_UPLOAD:
      return FileUploadScreenComponent;
    case EComponentTypes.QUESTION_DROPDOWN:
      return DropdownScreenComponent;
    case EComponentTypes.QUESTION_BOOLEAN:
      return BooleanScreenComponent;
    case EComponentTypes.QUESTION_CHECKLIST:
      return ChecklistScreenComponent;
    default: return null;
  }
};

export const ScreenComponent = ({
  item,
  index,
  highlight,
  onOpenComponent,
  ...props
}: ScreenComponentOwnProps) => {
  const handleClick = onOpenComponent && (() => onOpenComponent(item));

  const PreviewComponent = getComponent(item?.type);

  if (!PreviewComponent || !item) return null;

  const disableClick = item.type === EComponentTypes.ELEARNING_QUESTION;

  const classNames = ['ScreenComponent'];
  if (highlight) classNames.push('ScreenComponent--highlight');
  if (disableClick) classNames.push('ScreenComponent--disable');

  return (
    <div
      className={classNames.join(' ')}
      onClick={!disableClick ? handleClick : undefined}
    >
      {/* @ts-expect-error */}
      <PreviewComponent {...props} item={item} />
    </div>
  );
};

export default ScreenComponent;
