import Icon from '@common/components/icon';
import { ReceivedQuestionComponentSlider }
  from '@modules/learning/types/objects';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSlider, { ReactSliderProps } from 'react-slider';

type Props = {
  item: ReceivedQuestionComponentSlider;
  onChange?: ReactSliderProps['onChange'];
  value?: ReactSliderProps['value'] | null;
};

const getMaxValue = (component: ReceivedQuestionComponentSlider) => {
  const amount = component.parameters.amount_of_steps;
  const max = typeof amount === 'string' ? parseInt(amount, 10) : amount;
  return max > 3 ? max : 3;
};

const halfStep = 0.5;
const fullStep = 1;

const QuestionSlider = ({ item, onChange, value }: Props) => {
  const { t } = useTranslation();
  const maxValue = useMemo(() => getMaxValue(item), [item]);

  const step = useMemo(() => {
    if (typeof value !== 'number' && maxValue % 2 === 0) {
      return halfStep;
    }
    return fullStep;
  }, [maxValue, value]);

  const currentValue = useMemo(() => {
    if (typeof value !== 'number') {
      if (maxValue % 2 === 0) {
        return ((maxValue / 2) + halfStep);
      }
      return Math.ceil(maxValue / 2);
    }
    return value;
  }, [value, maxValue]);

  const renderThumb = useCallback((props, state) => {
    return (
      <div {...props}>
        {
          typeof value === 'number' ?
            (
              <span className="hasValue">{ state.valueNow }</span>
            ) : (
              <span>
                <Icon size={18} type="chevron_left" />
                <small>{ t('common:drag') }</small>
                <Icon size={18} type="chevron_right" />
              </span>
            )
        }
      </div>
    );
  }, [value, t]);

  let className = 'Screen__Component__Question Screen__Component__QuestionSlider';
  if (typeof value === 'number') {
    className += ' hasValue';
  }

  return (
    <div className={className}>
      <div className="Screen__Component__Question__Text">
        { item.parameters.text }
        {
          item.parameters.answer_required &&
          <span className="Screen__Component__Question__Text__Required">
            &nbsp;*
          </span>
        }
      </div>
      {item.parameters.description && (
        <div className="Screen__Component__Question__Description">
          { item.parameters.description }
        </div>
      )}
      <ReactSlider
        disabled={!onChange}
        min={1}
        max={maxValue}
        step={step}
        value={currentValue}
        onChange={onChange}
        thumbClassName="Screen__Component__QuestionSlider__Slider__Ball"
        trackClassName="Screen__Component__QuestionSlider__Slider"
        renderThumb={renderThumb}
      />
      <div className="Screen__Component__QuestionSlider__Labels">
        <div className="Screen__Component__QuestionSlider__Labels__Label">
          { item.parameters.label_left }
        </div>
        <div className="Screen__Component__QuestionSlider__Labels__Label">
          { item.parameters.label_center }
        </div>
        <div className="Screen__Component__QuestionSlider__Labels__Label">
          { item.parameters.label_right }
        </div>
      </div>
    </div>
  );
};

export default QuestionSlider;
