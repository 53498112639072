import * as React from 'react';
import Attachment from '../../../../../../common/components/attachment';

const ComponentImage = ({ item }) => {
  if (!item.parameters || !item.parameters.attachment) return null;

  return (
    <Attachment
      className="Screen__Component__Image"
      item={item.parameters.attachment}
    />
  );
};

export default ComponentImage;
