import React, { Component } from 'react';
import * as R from 'ramda';

import { combineClassNames } from '@common/utils/combineClassNames';
import { Select } from '@common/components/form/inputs/select';
import { Message } from '../../message';

export type SplitTimePickerOwnProps = {
  value?: string;
  minValue?: string;
  error?: string | boolean;
  disabled?: boolean;
  allowNoSelection?: boolean;
  hoursPlaceholder?: string;
  minutesPlaceholder?: string;
  noSelectionLabel?: string;
  className?: string;
  onChange?: (currentValue: string) => void;
};

type Option = {
  max: number;
  step?: number;
  minValue?: string;
  allowNoSelection?: boolean;
  noSelectionLabel?: string;
};

export class SplitTimePicker extends Component<SplitTimePickerOwnProps> {
  constructor(props: SplitTimePickerOwnProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  getMinValue() {
    const { value, minValue } = this.props;

    if (!minValue || !value) return ['00', '00'];

    let [hour, minute] = minValue.split(':');
    const [currentHour] = value.split(':');

    if (minute === '59') hour = (parseInt(hour, 10) + 1).toString();
    if (currentHour.toString() !== hour.toString()) minute = '00';

    return [hour, minute];
  }

  createOptions({
    max,
    step = 1,
    minValue = '0',
    allowNoSelection,
    noSelectionLabel = '',
  }:Option) {
    // @ts-expect-error it says step should not be there but I fear it's gonna break
    // stuff in case the library types are wrong
    const options = R.range(0, max, step)
      .map((i) => {
        const number = i * step;
        const disabled = number < parseInt(minValue, 10);
        const value = number < 10 ? `0${number}` : `${number}`;

        return { label: value, value, disabled };
      });
    if (allowNoSelection) {
      return [
        { label: noSelectionLabel, value: null, disabled: false },
        ...options
      ];
    }
    return options;
  }

  handleChange(key: number, newValue: string) {
    const { onChange } = this.props;

    const currentValue = this.getHoursMinutes();
    currentValue[key] = newValue;

    onChange!(currentValue.join(':'));
  }

  getHoursMinutes() {
    const { value, allowNoSelection } = this.props;
    const [hourValue, minuteValue] = (
      value?.split(':') ||
      (allowNoSelection ? ['', ''] : ['00', '00'])
    );
    return [hourValue, minuteValue];
  }

  render() {
    const {
      error, disabled, allowNoSelection, hoursPlaceholder, minutesPlaceholder,
      noSelectionLabel, className
    } = this.props;

    const [hourValue, minuteValue] = this.getHoursMinutes();
    const [minHour, minMinute] = this.getMinValue();

    const classNames = combineClassNames('TimePicker', {
      'TimePicker--invalid': !!error,
      'TimePicker--disabled': disabled,
      'allowNoSelection': !!allowNoSelection
    });

    return (
      <>
        <div className={`${classNames} ${className || ''}`}>
          <Select
            value={hourValue}
            placeholder={hoursPlaceholder}
            options={this.createOptions({
              max: 24,
              minValue: minHour,
              allowNoSelection,
              noSelectionLabel
            })}
            // @ts-expect-error
            onChange={(option: { value: string }) => {
              this.handleChange(0, option.value);
            }}
            onBlur={() => this.handleChange(0, hourValue)}
            clearable={false}
            disabled={disabled}
          />
          <Select
            value={minuteValue}
            placeholder={minutesPlaceholder}
            options={this.createOptions({
              max: 60,
              minValue: minMinute,
              allowNoSelection,
              noSelectionLabel
            })}
            // @ts-expect-error
            onChange={(option: { value: string }) => {
              this.handleChange(1, option.value);
            }}
            onBlur={() => this.handleChange(1, minuteValue)}
            clearable={false}
            disabled={disabled}
          />
        </div>
        <Message error={error} />
      </>
    );
  }
}
