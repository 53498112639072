import TextAreaInput from '@common/components/form/inputs/textarea/textarea-input';
import { ReceivedQuestionComponentText } from '@modules/learning/types/objects';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

export type QuestionTextValue = string;

type QuestionTextProps = {
  item: ReceivedQuestionComponentText;
  value?: QuestionTextValue;
  onChange?: (newValue: string) => void;
};

const QuestionText = memo(({
  item,
  value = '',
  onChange
}: QuestionTextProps) => {
  const { t } = useTranslation();
  return (
    <div className="Screen__Component__QuestionText">
      <div className="Screen__Component__Question__Text">
        { item.parameters.text }
        {
          item.parameters.answer_required &&
          <span className="Screen__Component__Question__Text__Required">
            &nbsp;*
          </span>
        }
      </div>
      {
        item.parameters.description && (
          <div className="Screen__Component__Question__Description">
            { item.parameters.description }
          </div>
        )
      }
      <TextAreaInput
        rows={8}
        value={value}
        disabled={!onChange}
        onChange={onChange!}
        placeholder={t('learning:component_type_question_text_placeholder')}
      />
    </div>
  );

});

export default QuestionText;
