import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { FileViewer } from '@common/components/file-viewer';
import Spinner from '@common/components/spinner';
import { DocumentIcon } from '@modules/documents/components/document-icon';
import { Attachment } from '@common/types/objects';
import { Document } from '@modules/documents/types';
import { FileInput } from '@common/components/form/inputs/file';
import type { ReceivedQuestionComponentFileUpload } from '../../../../types/objects';

type ComponentFile = File | Attachment;

export type FileUploadScreenComponentValue = ComponentFile[];

type FileUploadScreenComponentOwnProps = {
  item: ReceivedQuestionComponentFileUpload;
  value?: FileUploadScreenComponentValue;
  onChange?: (value: FileUploadScreenComponentValue) => void;
  dropzoneCallToAction?: string;
};

const fileKey = (file: ComponentFile) => {
  return file instanceof File ? URL.createObjectURL(file) : file.id;
};

export const FileUploadScreenComponent = ({
  item,
  value,
  onChange,
  dropzoneCallToAction,
}: FileUploadScreenComponentOwnProps) => {
  const { t } = useTranslation();
  const readOnlyMode = !onChange;
  const multiple = item?.parameters?.allow_multiple_uploads;

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileInPreview, setFileInPreview] = useState<string | undefined>(undefined);
  const [files, setFiles] = useState<(File | Attachment)[]>([]);

  const uploaded = useMemo(() => {
    return value?.filter((v) => !(v instanceof File)) as Attachment[] || [];
  }, [value]);

  const update = useCallback((file) => {
    if (file instanceof File) {
      setFiles((current) => (multiple ? [...current, file] : [file]));
    } else {
      setFiles((current) => {
        if (multiple) {
          const index = current!.findIndex((f) => (f instanceof File) &&
            f.name.split('.')[0] === file.file_name.split('.')[0]);
          if (index > -1) {
            const newValue = [...current];
            newValue.splice(index, 1, file);
            return newValue;
          }
        } else {
          return [file];
        }
        return current;
      });
    }
  }, [setFiles]);

  useEffect(() => {
    if (!readOnlyMode) {
      onChange(files);
      setIsUploading(!!files.filter((f) => f instanceof File).length);
    }
  }, [files, readOnlyMode]);

  return (
    <div className="Screen__Component__Question FileUploadScreenComponent">
      <div className="Screen__Component__Question__Text">
        { item.parameters.text }
        {
          item.parameters.answer_required && (
            <span className="Screen__Component__Question__Text__Required">
              &nbsp;*
            </span>
          )
        }
      </div>
      {
        item.parameters.description && (
          <div className="Screen__Component__Question__Description">
            { item.parameters.description }
          </div>
        )
      }
      {fileInPreview && <FileViewer
        isVisible
        content={uploaded.map((attachment) => ({ attachment }))}
        setIsVisible={(isVisible) => !isVisible && setFileInPreview(undefined)}
        initialPage={uploaded.findIndex(({ id }) => id === fileInPreview)}
      />}
      {
        value?.map((file: ComponentFile) => {
          return (
            <div key={fileKey(file)} className="filePreview">
              {
                (file instanceof File) ?
                  <Spinner size="large" centered /> :
                  <>
                    <DocumentIcon item={{ attachment: file } as Document} />
                    <a onClick={() => setFileInPreview(file.id)}>
                      { file.file_name }
                    </a>
                  </>
              }
              {
                !readOnlyMode && (
                  <Icon
                    type="delete"
                    onClick={() => {
                      setFiles(value.filter((valueFile: ComponentFile) => {
                        return file !== valueFile;
                      }));
                    }}
                  />
                )
              }
            </div>
          );
        })
      }
      {
        (!readOnlyMode || value === undefined) && (
          <FileInput
            className="Screen__Component__FileUploadDropzone"
            placeholder={t(
              dropzoneCallToAction ||
              'survey:question_type_file_upload_preview_dropzone_label'
            )}
            dragndrop
            processFile
            multiple={multiple}
            disabled={readOnlyMode || isUploading}
            onChange={update}
          />
        )
      }
    </div>
  );
};
