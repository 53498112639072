import React, { useCallback } from 'react';
import { ReceivedQuestionComponentBoolean }
  from '@modules/learning/types/objects';

export type BooleanScreenComponentValue = boolean | null;

type BooleanScreenComponentOwnProps = {
  item: ReceivedQuestionComponentBoolean;
  value?: BooleanScreenComponentValue;
  onChange?: (value: BooleanScreenComponentValue) => void;
};

export const BooleanScreenComponent = ({
  item,
  value,
  onChange
}: BooleanScreenComponentOwnProps) => {

  const onTrueToggle = useCallback(() => {
    if (onChange) {
      onChange(value === true ? null : true);
    }
  }, [onChange, value]);

  const onFalseToggle = useCallback(() => {
    if (onChange) {
      onChange(value === false ? null : false);
    }
  }, [onChange, value]);

  return (
    <div className="Screen__Component__Question BooleanScreenComponent">
      <div className="Screen__Component__Question__Text">
        { item.parameters.text }
        {
          item.parameters.answer_required && (
            <span className="Screen__Component__Question__Text__Required">
              &nbsp;*
            </span>
          )
        }
      </div>
      {
        item.parameters.description && (
          <div className="Screen__Component__Question__Description">
            { item.parameters.description }
          </div>
        )
      }

      <div className="Screen__Component__BooleanButtons">
        <span
          className={value === true ? 'selected' : ''}
          onClick={onTrueToggle}
          role="button"
        >
          { item.parameters.label_true }
        </span>
        <span
          className={value === false ? 'selected' : ''}
          onClick={onFalseToggle}
          role="button"
        >
          { item.parameters.label_false }
        </span>
      </div>
    </div>
  );
};
