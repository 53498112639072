import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceivedQuestionComponentNumber } from '@modules/learning/types/objects';
import { NumberInput } from '@common/components/form/inputs/number';

export type NumberScreenComponentValue = JSX.IntrinsicElements['input']['value'];

export type NumberScreenComponentProps = {
  item: ReceivedQuestionComponentNumber;
  onChange?: (n: string) => void;
  value?: NumberScreenComponentValue;
};

export const NumberScreenComponent = memo(({
  item,
  onChange,
  value
}: NumberScreenComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="NumberScreenComponent Screen__Component__Question">
      <div className="Screen__Component__Question__Text">
        { item.parameters.text }
        {
          item.parameters.answer_required &&
          <span className="Screen__Component__Question__Text__Required">
            &nbsp;*
          </span>
        }
      </div>
      <div className="Screen__Component__Question__Description">
        { item.parameters.description }
      </div>
      <NumberInput
        value={value}
        allowDecimals={item.parameters.allow_decimals}
        placeholder={t('survey:question_type_number_input_placeholder')}
        onChange={onChange}
      />
    </div>
  );
});
