import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@common/components/form/inputs/select';
import Icon from '@common/components/icon';
import type { ReceivedQuestionComponentDropdown } from '../../../../types/objects';

export type DropdownScreenComponentValue = string[];

export type DropdownScreenComponentOwnProps = {
  item: ReceivedQuestionComponentDropdown;
  onChange?: any; // TODO fix type
  value?: DropdownScreenComponentValue;
};

const arrowRenderer = () => <Icon type="arrow_drop_down" />;

export const DropdownScreenComponent = ({
  item,
  onChange,
  value
}: DropdownScreenComponentOwnProps) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return (item?.parameters?.options || []).map((option) => {
      return {
        label: option.value,
        value: option.id
      };
    });
  }, [item?.parameters?.options]);

  const multi = !!item.parameters.allow_multiple_options;

  const selectValue = useMemo(() => {
    if (!value) return undefined;
    if (multi) return value;
    return value[0];
  }, [multi, value]);

  return (
    <div className="Screen__Component__Question DropdownScreenComponent">
      <div className="Screen__Component__Question__Text">
        { item.parameters.text }
        {
          item.parameters.answer_required && (
            <span className="Screen__Component__Question__Text__Required">
              &nbsp;*
            </span>
          )
        }
      </div>
      {
        item.parameters.description && (
          <div className="Screen__Component__Question__Description">
            { item.parameters.description }
          </div>
        )
      }

      <Select
        value={selectValue}
        placeholder={t('survey:question_type_dropdown_input_placeholder')}
        options={options}
        arrowRenderer={arrowRenderer}
        onChange={onChange}
        multi={multi}
        clearable={false}
        disabled={!onChange}
      />
    </div>
  );
};
